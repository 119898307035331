<template>
  <div>
    <form @submit.prevent="submitHandler" v-form-enter v-if="!isLoading">
      <div class="formgrid grid flex align-items-center pb-3">
        <div class="field col-6">
          <h2>Permissions Profile</h2>
        </div>
        <div class="field col-6">
          <div class="p-inputgroup flex justify-content-end">
            <AInputSwitch v-model="active" class="green" />
          </div>
        </div>
        <Divider class="m-2 mb-5"></Divider>
        <div class="field col-12 sm:col-6">
          <label for="name">Profile Name</label>
          <div class="p-inputgroup">
            <InputText
              v-model="name"
              placeholder="Profile Name"
              class="green"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.name.required"
          >
            Profile name is required
          </div>
        </div>
      </div>
      <div class="grid mb-3" v-for="(p, index) in data" :key="index">
        <div
          class="col-12 flex flex-wrap align-items-center border-round"
          style="background-color: #f2f4f6"
        >
          <div class="col-12 sm:col-4">
            <h5 class="mb-0">
              {{ index }}
            </h5>
          </div>
          <div
            class="hidden sm:flex col justify-content-center"
            style="background-color: #f2f4f6"
            v-for="pE in permissionEnums"
            :key="pE.id"
          >
            {{ pE.name }}
          </div>
        </div>
        <div
          v-for="(i, pi) in p"
          :key="i.uid"
          class="flex col-12 flex-wrap align-items-center"
        >
          <Divider v-if="pi > 0" class="m-0 mb-3 sm:hidden" />
          <div class="col-12 sm:col-4">
            {{ i.name }}
          </div>

          <div
            class="flex col-4 sm:col-1 flex-wrap align-items-center flex-grow-0 sm:flex-grow-1 justify-content-around"
            v-for="pE in permissionEnums"
            :key="pE.id"
          >
            <div class="sm:hidden col-12 p-1 flex justify-content-center">
              {{ pE.name }}
            </div>
            <InputSwitch
              :value="checkPermission(pE.id, i.permission)"
              class="green"
              @change="updateMyValue(pE.id, i)"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-content-between pt-5">
        <Button type="submit"> SAVE </Button>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>
    <RocketLoad :full="true" v-else />
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "PermissionSliderSave",
  components: {},
  data() {
    return {
      submitted: false,
      isLoading: false,
      data: null,
      rawData: null,
      name: null,
      active: null,
      systemPermissions: null,
      loadingText: "Loading...",
      inputIsVisible: false,
    };
  },
  validations: {
    name: {
      required,
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.uid) {
      this.name = this.obj.name;
      this.active = this.obj.active;
      this.getPermission("/v1/users/permissions/" + this.uid);
    } else {
      this.active = 1;
      this.getPermission("/v1/users/permissions/system");
    }
  },
  methods: {
    getPermission(route) {
      this.loadingText = "Loading...";
      this.$axios
        .get(process.env.VUE_APP_ROOT_API + route, {})
        .then((response) => {
          var data = response.data.data;
          this.rawData = data;
          this.data = this.lodash.groupBy(data, (a) => a.group_name);
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO! You have no power here.");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    checkPermission(id, str) {
      var array = str.split(",");
      if (id === "F") {
        return array.length === this.permissionEnums.length - 1;
      }
      return array.indexOf(id) > -1;
    },
    updateMyValue(id, profile) {
      var array = profile.permission.split(",");

      if (id === "F") {
        if (array.length !== this.permissionEnums.length - 1) {
          array = [];
          for (let index = 1; index < this.permissionEnums.length; index++) {
            array.push(this.permissionEnums[index].id);
          }
        } else {
          array = [];
        }
      } else {
        if (array.indexOf(id) > -1) {
          array.splice(array.indexOf(id), 1);
        } else {
          array.push(id);
        }
      }

      profile.permission = array.join();
    },
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/users/permissions/" +
              (this.uid ? this.uid : ""),
            JSON.stringify({
              name: this.name,
              active: this.active,
              permissions: this.rawData,
              account_uid: this.account_uid,
            }),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.$toast.add({
              severity: "success",
              summary: "Permissions Saved",
              life: 3000,
            });
            this.isLoading = false;
            this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
  },
  computed: {
    obj() {
      return this.$store.getters.selectedObject;
    },
    uid() {
      if (this.obj) {
        return this.obj.uid;
      }
      return null;
    },
    user() {
      return this.$store.getters.user;
    },
    account_uid() {
      return this.user?.account_uid;
    },
  },
  watch: {},
};
</script>